import React from "react"
import { Parallax } from 'react-scroll-parallax'

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const AboutTratto = () => {
  const data = useStaticQuery(graphql`
    query LogoTrattoQuery {
      file(relativePath: {eq: "Tratto.png"}) {
        childImageSharp {
          fluid {
            base64
            src
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <Parallax className="page page--aboutTratto" y={[0, 20]}>
      <div className="page__content">
        <div className="content__column-image">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Tratto" />
        </div>

        <div className="content__column-text">
            <h3>PayMe usa o Tratto, um sistema de <br />pagamento moderno e seguro.</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

            <div class="row-buttons">
              <a href="http://tra.to/" className="btn" target="_blank" rel="noopener noreferrer">Conheça o Tratto</a>
              <a href="http://tra.to/" className="btn btn--primary" target="_blank" rel="noopener noreferrer">Cadastre-se</a>
            </div>
        </div>
      </div>
    </Parallax>
  )
}

export default AboutTratto
